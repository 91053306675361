'use client'

import { useEffect }                            from 'react';
import { console_log }                          from '@/libs/debug';
import { createContext}                         from 'react';
import { useState }                             from 'react';
import { useSession }                           from 'next-auth/react';

import { iUser }                                from '@/libs/interfaces'
import { Copy_from_authSession_to_ctxUser }     from '@/libs/auth';
/*
Un ejemplo de como se hace...
https://www.js-craft.io/blog/using-react-context-nextjs-13/
*/

//------------------------------------------------------
// 1) Crear el contexto
//inicializo los valores
const default_Context_User: iUser = {
    id                              : '',
    name                            : null,
    email                           : null,
    image                           : null,
    COD_CLTE                        : null, // 10 primavera CDC
    NOMBRE_CLTE                     : null, // "vacio",
    COD_USUARIO                     : null,
    COD_TEMPORADA                   : null,
    COD_ZONA                        : null,
    COD_CAMPEONATO                  : null,
    COD_EQUIPO                      : null,
    COD_JUGADOR                     : null,
    COD_CLUB                        : null,
    room                            : {},
    menuTree                        : [], // Asegúrate de que este array tenga la estructura correcta según iMenuTreeNode
    tabsGeneral                     : 'campeonato',
    tabsAlojamientos                : null,
    fechas_campeonato_definidas     : false,                     //para indicar si las fechas del campeonato han sido definidas.
    //prueba: '',

    // Agregar más propiedades si es necesario...
};

interface iContext_User {
    ctx_User   : iUser;
    sctx_User: React.Dispatch<React.SetStateAction<iUser>>;
    }
export const Context_User = createContext<iContext_User>({
    ctx_User: default_Context_User,
    sctx_User: () => {}, // Asignamos una función vacía como valor inicial
    });

//------------------------------------------------------
// 2) Crear el componente que provee el contexto

type Props = {
    children: React.ReactNode,
    pContext_User?: iUser,
    }
export const Context_User_Provider = ({ children, pContext_User}: Props) => {
    //const [ctx_User, sctx_User] = useState<iUser>(initialUser);
    const [ctx_User, sctx_User] = useState<iUser>(pContext_User || default_Context_User);
    const { data: session } = useSession();
    //console.log("definiendo Context_User_Provider");

    useEffect(() => {
        const update_ctxUser = async () => {
            if (session) {
                await Copy_from_authSession_to_ctxUser(ctx_User, sctx_User);
                }
            };
        update_ctxUser();
        }, [session]);


    return (
        <Context_User.Provider value={{ ctx_User, sctx_User}}>
            <>
            {
            //console_log (['Context_User_Provider > ctx_User', ctx_User], "ctx tabsGeneral")
            }
            {children}
            </>
        </Context_User.Provider>
        );
    };

