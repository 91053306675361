import { cloneDeep }                        from 'lodash-es';
import { getSession }                       from 'next-auth/react';
import { iUser }                            from '@/libs/interfaces';

export const Copy_from_authSession_to_ctxUser = async (p_ctx_User: iUser, p_sctx_User: React.Dispatch<React.SetStateAction<iUser>> ) => {
    //console.log ("dentro de... Copy_from_authSession_to_ctxUser")
    //actualizo el ctx_User con lo obtenido
    try {
        const authSession = await getSession(); //Espera a que la sesión se actualice
        if (authSession) {
            // Actualiza el ctx_User con lo obtenido
            let new_ctx_User = cloneDeep(p_ctx_User);
            //pongo las propiedades que devuelve como minimo nextauth
            new_ctx_User.id = authSession.user.id;
            new_ctx_User.email = authSession.user.email;
            new_ctx_User.name = authSession.user.name;
            new_ctx_User.image = authSession.user.image;
            //pongo las propiedades que me interesan, quizas a futuro añada mas, por ahora con estas...
            new_ctx_User.COD_CLTE = authSession.user.COD_CLTE;
            new_ctx_User.NOMBRE_CLTE = authSession.user.NOMBRE_CLTE;
            new_ctx_User.COD_USUARIO = authSession.user.COD_USUARIO;
            //new_ctx_User.COD_CAMPEONATO = authSession.user.COD_CAMPEONATO;
            new_ctx_User.COD_PERFIL_USUARIO = authSession.user.COD_PERFIL_USUARIO;
            //new_ctx_User.APELLIDO1_USUARIO = authSession.user.APELLIDO1_USUARIO;
            //new_ctx_User.APELLIDO2_USUARIO = authSession.user.APELLIDO2_USUARIO;
            //new_ctx_User.EMAIL_USUARIO = authSession.user.EMAIL_USUARIO;
            //new_ctx_User.NOMBRE_USUARIO = authSession.user.NOMBRE_USUARIO;
            p_sctx_User(new_ctx_User);
            }
        return true;
        } 
    catch (error) {
        console.log("ERROR al obtener la sesión:", error);
        return false;
        }
    }
