import { isDevelopmentDebug }                                        from '@/libs/general';
import { Prisma_sql_to_string }                                 from '@/libs/db_prisma';

/* 
Esta funcion es para que muestre por consola solo en modo desarrollo
(...messages: any[]): Usando el operador de propagación (...), la función puede aceptar cualquier número de argumentos y 
                      los pasará directamente a console.log. 
                      Y al poner [] tb acepta arreglos
*/

//creo un console_log personalizado que me permite mostrar los mensajes de console.log que me interese en cada momento.
//const console_log_show = false;
//console.clear();
let console_log_show = ""; 
console_log_show = "";     //muestro todos los console_log 
//console_log_show = "none"; //no muestro ningún console_log
//console_log_show = "get_JSON";
//console_log_show = "Temporadas";
//console_log_show = "Zonas";
//console_log_show = "Campeonatos";
//console_log_show = "Campeonato";
//console_log_show = "TreeViewMenu";
//console_log_show = "handle TreeViewMenu click";
//console_log_show = "jugadores";
//console_log_show = "un nodo abierto por nivel";
//console_log_show = "ctx menuTreeNodes";
//console_log_show = "ctx tabsGeneral";
//console_log_show = "ctx tabsGeneral autoelegir con menuTree";
//console_log_show = "data_with_API";
//console_log_show = "form_controller API";
//console_log_show = "TryCatch_SERVER";
//console_log_show = "form_controller DB";
//console_log_show = "Accomodations";
//console_log_show = "DeleteUpdate Accommodation";
//console_log_show = "upsert Accommodation";
//console_log_show = "load Accommodations";
//console_log_show = "prepost actions";
//console_log_show = "asignar jugador a habitacion";
//console_log_show = "delete alojamiento";
//console_log_show = "treeViewMenu.tsx > useEffect";
console_log_show = "no muestro ninguno";
export const console_log = (content:any | any[], type?:string) => {
    let show_message = false;

    //if (process.env.NODE_ENV === 'development') {
    if (isDevelopmentDebug()) {
        if (console_log_show === "")        {show_message = true;}
        else if (console_log_show && type) {
            if (console_log_show === type)  {show_message = true;}
            }

        if (show_message) {
            //console.log ("typeof content:", typeof content);
            if (typeof content === 'object') {
                console.log(content[0] + "...");
                console.log({...content}); 
                } 
            else {
                console.log(content);
                } 
            }
        }
        
    }



export const query_debug = (pQuery:any) => {
    if (isDevelopmentDebug()) {
        //console.log("query_debug raw:", pQuery);
        console.log("query_debug:\n", Prisma_sql_to_string(pQuery));
        }
    }
//pongo never, para indicar a TypeScript que la función lanzará un error o excepción y, por lo tanto, 
//no regresará un valor ni permitirá continuar el flujo del programa.
export function throwCustomError(pMmessage: string): never{
    const error = new Error(pMmessage) as Error & { isCustomError: boolean };
    error.isCustomError = true;
    throw error;
    }