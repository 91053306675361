import { iUser, tValue, }                                  from '@/libs/interfaces';



//con la siguient función compruebo:
// - que estoy en modo desarrollo
// - que tengo activado el debug en el fichero .env.development 
export const isDevelopmentDebug = () => {
    const process_env = (process.env.NODE_ENV === 'development');
    const env_NEXT_PUBLIC_DEV_DEBUG = (process.env.NEXT_PUBLIC_DEV_DEBUG === "true") 
    return (process_env && env_NEXT_PUBLIC_DEV_DEBUG);
    }

//pongo la siguiente para hacer pruebas y ver si esta función es la causante de los problemas con turbopack.
export const isDevelopmentDebug_PRUEBA = () => true; //--> aunque haga esto sigue sin funcionar, asi que no es esta función la causante del problema

export function getFileName(pPath: string): string {
    if (!pPath) return '';
    // Obtener todo después de la última ocurrencia de '\'
    const pos_of_slash = pPath.lastIndexOf('\\') ;
    const fileName = pPath.substring(pos_of_slash + 1);
    // Quitar la extensión 
    const fileNameWithoutExtension = fileName.split('.')[0];
    return fileNameWithoutExtension;
    }

export function obfuscate_URL_DATABASE(pOnlyInstanceAndDB?: boolean): string {
    let url_database_obfuscated = "";
    // Obtener todo después de la última ocurrencia de '\'
    const url_database = process.env.URL_DATABASE
    // Dividir la cadena de conexión en partes usando ';' como separador
    if (url_database) {
        const arr_url_database: string[] = url_database.split(';');
        // Reconstruir la cadena excluyendo cualquier parte que contenga 'password'
        url_database_obfuscated = arr_url_database.filter(parte => !parte.includes('password')).join(';');
        if (pOnlyInstanceAndDB) { //creo que no se usa, pero no pide pan.
            // Obtener solo la instancia y la base de datos
            const serverRegex = /sqlserver:\/\/([^;]+);/;
            const databaseRegex = /database=([^;]+);/;
          
            const serverMatch = url_database_obfuscated.match(serverRegex);
            const databaseMatch = url_database_obfuscated.match(databaseRegex);
          
            if (serverMatch && databaseMatch) {
                const serverName = serverMatch[1];
                const databaseName = databaseMatch[1];
                url_database_obfuscated =  `${serverName} - ${databaseName}`;
                }
            }
        }
    return url_database_obfuscated;
    }



export function getItem(pArray: any[], pPropertyName: string, pPropertyValue: tValue): any {
    const item = pArray.find(item => item[pPropertyName] === pPropertyValue);
    return item;
    }
    
export function setItem(pArray: any[], pPropertyName: string, pPropertyValue: tValue, pNewItem: any): any[] {
    return pArray.map(item => 
        item[pPropertyName] === pPropertyValue ? pNewItem : item
        );
    }

/* export function isTrue_v0(pValue: any): boolean {
    // Convertir el valor a minúsculas si es un string
    const value = typeof pValue === 'string' ? pValue.toLowerCase() : pValue;
    // Lista de representaciones válidas para el valor verdadero
    //on: para los campos de tipo switch en los que el valor true es 'on'
    const myTrueValues = [1, "1", true, "verdadero", "true", "on"];
    // Comprobar si el valor dado está en la lista de representaciones verdaderas
    return myTrueValues.includes(value);
    } */
//Tb vale para true/false ya que lo convierte en string 
// null, "" o undefined devuelven false
export function isTrue(pValue: any) {
    const trueValues = ['true', 'verdadero', '1', 'yes', 'si', 'on'];
    return trueValues.includes(String(pValue).toLowerCase());
    }

export function isValidNumber(pValue: any) {
    const num = Number(pValue); // Convertir el valor a número
    return typeof pValue !== 'boolean' && pValue !== null && pValue !== '' && !isNaN(num) && isFinite(num);
    }


export function capitalize(pText: string): string {
        // Verifica que el texto no esté vacío
    if (pText && pText.length > 0) {
        // Convierte la primera letra a mayúscula y el resto a minúsculas
        return pText.charAt(0).toUpperCase() + pText.slice(1).toLowerCase();
        }
    return pText;
    }


//2024_05_23: esta funcion no es tanto de FormController sino de general, pero la pongo aqui para tenerla a mano y porque por ahora solo la uso en temas alrededor de FormController
export const Copy_ObjectProperties = (pSourceObj: any, pDestinationObj: any) => {
    Object.keys(pDestinationObj).forEach(key => {
        if (pSourceObj.hasOwnProperty(key)) {
            pDestinationObj[key] = pSourceObj[key];
            }
        });
    return pDestinationObj;
    };

//inicialmente habia definido un parametro en <Accommodations /> para indicar que solo mostrara los accommmodations de un club en concreto, pero como
//tambien hace falta tomar esa decisión en otros componentes como Rooms observo si muestro o no cada cosa en base al estado de ctx_User.COD_CLUB y ctx_User.tabs
export const get_COD_CLUB_to_filter = (pCtx_User: iUser) => {
    let COD_CLUB = null;
    //si estamos en...
    //...la pestaña general de club 
    //...y dentro de ella en la de alojamientos 
    // y además tenemos un club seleccionado (es decir que ctx_User.COD_CLUB no es null ni 0)
    //En ese caso devuelve el valor de ctx_User.COD_CLUB indicando que quiero ver los alojamientos/habitaciones de ese club
    if (pCtx_User.tabsGeneral === 'club' && pCtx_User.tabsClub === 'alojamientos' && pCtx_User.COD_CLUB && pCtx_User.COD_CLUB !== 0) {
        COD_CLUB = pCtx_User.COD_CLUB;
        }
    return COD_CLUB;
    }


//comento las siguients ya que uso las funcs de lodash
/*
export function isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
    }

export function isEmpty(variable: any): boolean {
    if (variable === null || variable === undefined) {return true; }                            // Verificar si es null o undefined
    if (typeof variable === 'string' && variable.trim().length === 0) {return true; }           // Verificar si es una cadena vacía o solo de espacios
    if (typeof variable === 'object' && Object.keys(variable).length === 0) {return true; }     // Verificar si es un objeto y no tiene propiedades
    return false; // Si no se cumplen las condiciones anteriores, la variable no está vacía
    }
*/