// Importa los íconos de Material UI
import MenuIcon                                     from '@mui/icons-material/Menu';
import ExpandMoreIcon                               from '@mui/icons-material/ExpandMore';
import ChevronRightIcon                             from '@mui/icons-material/ChevronRight';
import AddIcon                                      from '@mui/icons-material/Add';
import DeleteIcon                                   from '@mui/icons-material/Delete';
import EditIcon                                     from '@mui/icons-material/Edit';
//import RoomPreferencesIcon                        from '@mui/icons-material/RoomPreferences';
import RoomPreferencesOutlinedIcon                  from '@mui/icons-material/RoomPreferencesOutlined';
import AdminPanelSettingsOutlinedIcon               from '@mui/icons-material/AdminPanelSettingsOutlined';
//import BedIcon                                    from '@mui/icons-material/Bed'
import HotelIcon                                    from '@mui/icons-material/Hotel';
import HotelOutlinedIcon                            from '@mui/icons-material/HotelOutlined';
import MeetingRoomOutlinedIcon                      from '@mui/icons-material/MeetingRoomOutlined';
import MeetingRoomIcon                              from '@mui/icons-material/MeetingRoom';
import PeopleIcon                                   from '@mui/icons-material/People';
import PersonIcon                                   from '@mui/icons-material/Person';
import ShieldOutlinedIcon                           from '@mui/icons-material/ShieldOutlined';
//import CheckOutlinedIcon                            from '@mui/icons-material/CheckOutlined';
import HorizontalRuleOutlinedIcon                   from '@mui/icons-material/HorizontalRuleOutlined';
import AdminPanelSettingsIcon                       from '@mui/icons-material/AdminPanelSettings';
import HailIcon                                     from '@mui/icons-material/Hail';
//import KeyIcon                                      from '@mui/icons-material/Key';
//import KeyOffIcon                                   from '@mui/icons-material/KeyOff';
import VpnKeyIcon                                   from '@mui/icons-material/VpnKey';
import AutoFixHighIcon                              from '@mui/icons-material/AutoFixHigh';
import KeyboardArrowDownIcon                        from '@mui/icons-material/KeyboardArrowDown';
import LoginIcon                                    from '@mui/icons-material/Login';
import LogoutIcon                                   from '@mui/icons-material/Logout';   
//import HourglassTopIcon                             from '@mui/icons-material/HourglassTop';
import HourglassEmptyIcon                           from '@mui/icons-material/HourglassEmpty';

import { SvgIconComponent } from '@mui/icons-material';

// Reexporta los íconos con nombres personalizados que empiezan con minúsculas
export const Menu           : SvgIconComponent = MenuIcon;
export const Expanded       : SvgIconComponent = ExpandMoreIcon;
export const NotExpanded    : SvgIconComponent = ChevronRightIcon;
export const Item           : SvgIconComponent = HorizontalRuleOutlinedIcon;
export const Add            : SvgIconComponent = AddIcon;
export const Delete         : SvgIconComponent = DeleteIcon;
export const Edit           : SvgIconComponent = EditIcon;
export const RoomType       : SvgIconComponent = RoomPreferencesOutlinedIcon;
export const Club           : SvgIconComponent = ShieldOutlinedIcon;
export const User           : SvgIconComponent = AdminPanelSettingsOutlinedIcon;
export const Booking        : SvgIconComponent = HotelOutlinedIcon;
export const Booking2       : SvgIconComponent = HotelIcon;
//export const Room         : SvgIconComponent = MeetingRoomIcon;
export const Room           : SvgIconComponent = MeetingRoomOutlinedIcon;
export const Players        : SvgIconComponent = PeopleIcon;
export const People         : SvgIconComponent = PeopleIcon;
export const Player         : SvgIconComponent = PersonIcon;
export const Delegate       : SvgIconComponent = AdminPanelSettingsIcon; //Delegado
export const Companion      : SvgIconComponent = HailIcon; //Acompañante
export const IsUser         : SvgIconComponent = VpnKeyIcon;
export const Dashboard      : SvgIconComponent = AutoFixHighIcon;
export const ArrowDown      : SvgIconComponent = KeyboardArrowDownIcon;
export const Login          : SvgIconComponent = LoginIcon;
export const Logout         : SvgIconComponent = LogoutIcon;
export const Age            : SvgIconComponent = HourglassEmptyIcon;
//export const IsNotUser      : SvgIconComponent = KeyOffIcon;



// Puedes seguir añadiendo más íconos aquí siguiendo el mismo patrón
