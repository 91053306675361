'use client';
import { backdropClasses } from '@mui/material';
import { red, grey, blue, green }   from '@mui/material/colors';
// @ts-ignore
import { createTheme, darken, lighten, Theme }       from '@mui/material/styles';
import ThemeProvider                from '@mui/material/styles/ThemeProvider';



//defino aqui fuera aquellas propiedades que se reutilizan en la propia definición del mui_theme_Customized para que sea posible
const border_radius = '4px';
const one_line = {
                    whiteSpace: 'nowrap', // Evita el salto de línea
                    overflow: 'hidden', // Oculta el texto que se desborde del elemento
                    textOverflow: 'ellipsis', // Pone puntos suspensivos al final del texto que se desborde
                    //border:"1px solid red",
                    }
const appbar_height = '64px';
const page_fullheight = 'calc(100vh - ' + appbar_height + ')'

//según chatgpt poniendolo lo customizado en un objeto aparte, se puede usar theme.palette para acceder a propiedades standar
//pero para empezar me obliga a poner "@ts-ignore" en la linea que se usa theme.palette -> NO se que he hecho que ya no lo pone en rojo
const mui_theme_Customized = (theme: Theme) => ({
    border_sections: '1px solid ' + blue[100],
    border_radius: border_radius,
    border_color2: grey[500],
    drawer_max_width: '300px',
    one_line: one_line,
    appbar_height: appbar_height,
    page_fullheight: page_fullheight,
    col1_background: lighten(theme.palette.primary.main, 0.97),
    font_size: {
                small:  '13px', 
                normal: '16px', 
                big:    '19px', 
                },
    MuiBox: {
        //para que un box ocupe todo el alto de la pantalla y sus elementos
        //estén centrados horizontalmente y verticalmente
        full_and_center: {
            //border: '1px dotted green',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // vertical
            alignItems: 'center', // horizontal
            //minHeight: '100vh',
            //height: '100vh',
            height: page_fullheight,
            textAlign: 'center',
            //background: 'red',
            }
        },
    MuiTooltip: {
        fontSize: 11,
        //color: "cyan",
        //* hide_between_tooltips
        //un poco largo, pero necesario para conseguir que se oculte al mover el puntero fuera del elementno que lo disparado
        //explicación...
        // PopperProps: "distancia" un poco mas abajo el tooltip para que cuando se mueva el puntero desaparezca al ver que estamos fuera y no se solapa el elemento que lo dispara y el tooltip
        // slotProps: margen superior a X para que no haya tnatan distancia con el tooltip, debido al PopperProps anterior y el propio margen superior del tooltip que se pone por defecto.
        hide_between_tooltips: {
            PopperProps: {
                modifiers: [{
                    name: "offset",
                    options: {
                        offset: [0, 2], // Ajuste global: Xpx a la derecha, Ypx hacia abajo
                        },
                    }],
                },
            slotProps: {
                tooltip: {
                        sx: {
                        marginTop: '3px !important', // Establece el margen superior a 0
                        },
                    },
                }
            },

        },
    MuiTab: {
        color: 'silver',
        type_btn: {
            color: 'silver',
            borderColor: 'silver',
            borderWidth: 1,
            borderStyle: 'solid',
            //borderRadius: mui_theme.customized.border_radius,
            //borderRadius: '4px',
            //marginTop: 1,
            marginRight: 1,
            padding: '0px',
            '&.Mui-selected': { // Estilos para la pestaña seleccionada
                color:theme.palette.primary.main, 
                borderColor: theme.palette.primary.main, 
                //color: theme.palette.warning.main,
                //borderColor: theme.palette.text.primary, 
                },
            //'.MuiTabs-indicator': { // Selector para el indicador de la pestaña seleccionada
            //    backgroundColor: 'transparent', // Hace transparente el indicador
            //    },
            },
        type_icon: {
            nada: 'nada',
            //border: '1px dotted ' + blue[100],
            padding: '0px',
            width: '30px',
            minWidth: '30px',
            icon : {
                fontSize: '1.2rem',
                borderRadius: '50%', // Hace el IconButton circular
                color: theme.palette.primary.main, 
                border: '1px solid', 
                borderColor: theme.palette.primary.main, 
                }
            }, 
        },
    MuiTreeItem: {
        Mui_selected: 'rgba(25, 118, 210, 0.08)',
        },
    MuiFab: {
        size_px: '25px'
        },
    MuiIconButton: {
        border: '1px dotted ' + theme.palette.primary.main,
        borderWidth: 0,
        color: "yellow",
        },
    /* MuiButton: {
        textTransform: 'none', 
        //color: 'inherit', // Esto mantiene el color por defecto del texto
        borderStyle: 'dashed',
        }, */
    MuiIcon: {
        fontSize: '1.0rem',
        fontSize_small: '0.8rem', //no pongo estas propiedades en un objeto ya que fontsize se usa por ejemplo en src\components\customized_mui\Icon_customized.tsx
        color: theme.palette.primary.main,
        //color2: grey[500],
        color2: theme.palette.primary.light,
        color3: grey[500],
        color_black: grey[700],
        spacing: "4px",
        },
    Forms: {
        maxWidth: '550px', //ancho del form por defecto
        CardContent: {
            display: 'flex', flexDirection: 'column', alignItems: 'flex-start' //para que cada elemento del form este en una linea
            //, border: '1px solid ' + grey[300],
            }
        
        },
    //lo siguiente lo utilizo en Alojamientos y Clubs para distribuir intuivamente los elementos
    Column: {
        Head: {
            display: 'flex',
            justifyContent: 'space-between', // Alinea los elementos a los extremos opuestos
            alignItems: 'center',            // Centra verticalmente los elementos, así se alinean los iconos verticalmente
            border: "1px dotted " + mui_theme.palette.primary.light,
            borderRadius: "5px",
            p: 1,
            minWidth: "200px",
            name: {
                fontWeight: 'bold',
                mr: 1,
                maxWidth: '150px',
                ...one_line
                },
            icons: {
                ...one_line,
                //border:"1px solid yellow",
                }
            },
        Element: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            mb: 1,
            border: '1px dashed transparent',
            borderRadius: border_radius,
            p:1,
            },
        Element_selected: {
            //border: '1px dashed ' + mui_theme.palette.grey[500],
            border: '1px dashed ' + mui_theme.palette.warning.main,
            },
        },

    });

const mui_theme = createTheme({
    //las posibles propiedades de mui, aqui..
    //...https://mui.com/material-ui/customization/default-theme/

    palette: {
        //primary: {main: red[500],}, //lo habilito para ver pruebas
        //primary: {main: green[500],}, //lo habilito para ver pruebas
        //primary: {main: green[400],}, //lo habilito para ver pruebas
        //primary: {main: blue[800],}, //lo habilito para ver pruebas
        },
    typography: {
        //por defecto
        //htmlFontSize: 16, //para que 1rem sea 10px
        //fontSize: 14, //para establecer un tamaño de fuente predeterminado específico para los componentes de MUI.
        //pero si lo cambio no se controlarlo ya que cambia los tamñanos relativas de las fuentes y no entiendo como
        //algo explican aqui...
        //...https://mui.com/material-ui/customization/typography/
        //htmlFontSize: 12, //establezco el 1rem
        //fontSize: "0.875rem", //14px
        //fontSize: "0.5rem", 
        //fontSize: "body2", 
        },
    /* customized: {

        }  */



        

    });

// Aplicar los estilos personalizados al tema
(mui_theme as any).customized = mui_theme_Customized(mui_theme);

// Extensión del tipo Theme para incluir los campos personalizados
// 
declare module '@mui/material/styles' {
    interface Theme {
        customized: ReturnType<typeof mui_theme_Customized>;
        }

    // Permitir el uso del campo `customized` en el objeto theme
    interface ThemeOptions {
        customized?: ReturnType<typeof mui_theme_Customized>;
        }
    }


export default mui_theme;


