import React, { useState, useRef, useEffect }                   from 'react';
import Box                                                      from '@mui/material/Box';
import Tooltip                                                  from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import { omit }                                                 from 'lodash-es';

import { styled }                                               from '@mui/material/styles';
import { TooltipProps, tooltipClasses }                         from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

interface props {
    // Aquí puedes definir las propiedades de tu componente si necesitas algunas
    pData: any;
    pLabel?: string;
    pLabelColor?: string;
    pShow?: boolean;
    pExpanded?: boolean;
    pOnlyTooltip?: boolean;
    pExcludedProps?: string[];
    pWidth?: number;
    pHeight?: number;
    pOpenMode?: 'click' | 'hover';

    }

const DebugBox: React.FC<props> = (props) => {
    //OJO, esto hay que ponerlo antes de la siguiente condición que a lo mejor provoca un return null y vercel no permite usar un useState despues de una posible salida del componente.
    //con la siguiente estado y lo añadido alrededor controlo que se abre o cierre con "click" o "hover" según pOpenMode
    const [stt_Open, sstt_Open] = useState(false);

    const show = true

    // Si pShow es false, no renderizar nada
    // tb miro a ver si no estoy en desarrollo
    if (!show || !props.pShow || process.env.NODE_ENV !== 'development') {
    //if (!show || !props.pShow ) {
        return null;
        }


    //-------------------------------------------------------------------------------------
    //* color label automatico en función del color de fondo
    //  hecho por chatgpt, no va fino asi que lo comento
    /* const boxRef = useRef<HTMLDivElement>(null);
    const [textColor, setTextColor] = useState<string>('orange');
    const getBackgroundColor = (element: Element | null): string => {
        let color = '';
        while (element) {
            color = window.getComputedStyle(element).backgroundColor;
            if (color !== 'rgba(0, 0, 0, 0)' && color !== 'transparent') {
                break;
                }
            element = element.parentElement;
            }
        return color;
        }
        
    const textColorBasedOnBackground = (backgroundColor: string): string => {
        console.log ("label" + props.pLabel + " backgroundColor: ", backgroundColor)
        //if (backgroundColor === 'rgb(255, 255, 255)') { // Si el fondo es blanco
        if (backgroundColor === 'rgba(0, 0, 0, 0)') { // el anterior no iba, por ensayo-error con el console.log superior esta si funciona
            return 'orange';
            } 
        else {
            return 'white'; // Otro color que contrasta con fondos más oscuros
            }
        }

    useEffect(() => {
        if (boxRef.current) {
            const backgroundColor = getBackgroundColor(boxRef.current);
            const color = textColorBasedOnBackground(backgroundColor);
            setTextColor(color);
        }
        }, []); */
    //-------------------------------------------------------------------------------------
          




    const handleTooltipToggle = (pEvent: React.MouseEvent<HTMLDivElement>) => {
        pEvent.stopPropagation(); //para que solo se haga esto
        if (props.pOpenMode === 'click') {
            sstt_Open(!stt_Open);
        }
    };
    //const handleTooltipClose    = () => {if (props.pOpenMode === 'click') {sstt_Open(false);        }};
    const handleMouseEnter      = () => {if (props.pOpenMode !== 'click') {sstt_Open(true);         }};
    const handleMouseLeave      = () => {if (props.pOpenMode !== 'click') {sstt_Open(false);        }};


    const aux_width = props.pWidth || 300;
    const aux_height = props.pHeight || 400;
    const aux_color = props.pLabelColor || "red";

    //hago una copia de los datos excluyendo las posibles propiedades que se han pedido ignorar
    let pData = omit(props.pData, props.pExcludedProps || [""]);
    // Añade de nuevo esas propiedades con el valor "..." al objeto para saber que habia algo
    if (props.pExcludedProps) {
        props.pExcludedProps.forEach((prop: string) => {
            //para evitar que la oculte si no existe la propiedad
            if (prop in props.pData) { //lo miro en props.pData ya que en pData ya no existen las propiedades excluidas
                // @ts-ignore
                pData[prop] = "...OCULTADA...";
                }
            });
        }


    //if (props.pExcludedProps) {props.pExcludedProps.forEach(prop => {delete pData[prop];});}

    const jsonRaw = JSON.stringify(pData);     // Convertir el objeto a string formateado
    const jsonRaw100chars = jsonRaw.slice(0, 100);     
    const jsonRaw300chars = jsonRaw.slice(0, 300);     
    const jsonFormated = JSON.stringify(pData, null, 4);     // Convertir el objeto a string formateado
    //const lines = jsonFormated.split('\n');// Dividir el string en líneas
    //const num_lines = 5;               // Número de líneas a mostrar
    //const summaryText = lines.slice(0, num_lines).join('\n'); // Preparar el texto resumido para mostrar directamente
    // Preparar el texto completo para el tooltip
    //const fullText = lines.join('\n');

    //para poder dar el ancho que me interese al tooltip sigo la guia que hay en...
    //https://mui.com/material-ui/react-tooltip/#variable-width
    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: props.pWidth,
        },
      });


    return (
        <>
        {props.pLabel && !props.pOnlyTooltip && <Box sx = {{color: aux_color }}>debugging {props.pLabel}...</Box>}
        {
        props.pExpanded ? (
            <Box
                sx = {{
                    //maxHeight: '500px', 
                    maxHeight: aux_height + 'px', 
                    overflowY: 'auto', 
                    maxWidth: 'none', 
                    padding: "10px",
                    border: "1px dashed orange",
                    }}
                >
                <pre>{jsonFormated}</pre>
            </Box>
            
            ) 
        : (
            <CustomWidthTooltip 
                //className = 'DebugBox_tooltip' //no se porque este estilo se lo pone donde tb esta el estiolo DEbugBox_link
                open={stt_Open}
                sx = {{
                    //maxWidth: 'none', width: '100%', backgroundColor: 'pink', border: "2px solid red"
                    }}
                title={
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                minWidth: '100px',
                                alignItems: 'center',
                                //borderBottom: '1px dashed silver',
                                }}
                            >
                            <Typography
                                    sx = {{
                                        color: "silver", 
                                        fontWeight: "bold",
                                        fontSize: ".8rem",
                                    
                                        }}
                                    >{props.pLabel || ''}</Typography>
                            <IconButton
                                aria-label="close"
                                onClick={() => sstt_Open(false)}
                                sx={{
                                    position: 'absolute', top: "-3px", right: -3,
                                    color: 'silver',
                                    fontSize: '0.2rem',
                                }}
                                >
                                <CloseIcon 
                                    sx={{
                                        fontSize: '1rem',
                                        }}
                                    />
                            </IconButton>
                        </Box>
                        <pre
                            //style={{ maxHeight: '500px', minWidth: '500px', overflowY: 'auto' }}
                            style={{ 
                                maxHeight: aux_height + 'px', overflowY: 'auto', maxWidth: 'none', 
                                //aux_width: width + 'px',
                                //aux_width: '500px', //no funciona como entiendo que debería
                                padding: "10px",
                                }}>
                            {jsonFormated}
                        </pre>

                    </>
                    } 
                placement="bottom-start" 
                arrow>
                    {props.pOnlyTooltip 
                    ? (
                        <Box className = 'DebugBox_link' 
                            //ref={boxRef} //* color label automatico
                            //sx = {{color: textColor  }} // * color label automatico
                            sx = {{color: aux_color,
                                   fontStyle: 'italic',
                                   //display: 'block',
                                   //border: '1px dashed orange',

                              }} 
                            onClick={handleTooltipToggle}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            >{props.pLabel}</Box>
                        ) 
                    : (
                        <Box style={{ whiteSpace: 'pre-wrap', cursor: 'pointer', border: "1px dashed orange" }}>
                            {jsonRaw100chars}
                            {(jsonRaw.length > jsonRaw100chars.length) && '...'} {/* Añadir puntos suspensivos si se ha truncado */}
                        </Box>
                    )
                    }

            </CustomWidthTooltip>
            )
        }
        </>
        )
    }

export default DebugBox;