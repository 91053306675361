/*
obtenido en...
https://www.prisma.io/docs/orm/more/help-and-troubleshooting/help-articles/nextjs-prisma-client-dev-practices
defino lo necesario para poder usar prisma en el codigo y que no me de error...

"warn(prisma-client) There are already 10 instances of Prisma Client actively running."

en dicha pagina me plantea un codigo que al decirle a chatgpt que quiero que conviva con TryCatch_API
me lo encapsula en la función

*/

import { PrismaClient, Prisma }                 from '@prisma/client'
import { console_log }                          from '@/libs/debug';
import { iFormController_body }                 from '@/libs/interfaces'; 
import { toast }                                from 'sonner';
import { throwCustomError }                     from '@/libs/debug';
//import { isDevelopmentDebug }                        from '@/libs/general';

// Extender el tipo global en el mismo archivo (aunque no se recomiende, ya que se recomienda hacerlo en un archivo .d.ts)

//declare global {var prisma: PrismaClient }
declare global {var prisma: PrismaClient | undefined;}


function Create_PrismaClient(): PrismaClient {
    let prisma = null;
    //para permitir depuración (por ahora var ver mejor las queryRaw)
    if (process.env.NODE_ENV === 'production') {
        prisma = global.prisma ?? new PrismaClient()
        }
    else {
         if (!global.prisma) {
            const bln_debug = false;
            if (!bln_debug) {
                global.prisma = new PrismaClient()
                }
            else {

                //NOTA: parece que para que funcione tiene que se ejecutado bajo "pnpm run dev", bajo el debug de vscode no funciona
                //para depurar las querys reales que se ejecutan
                //https://www.prisma.io/docs/orm/prisma-client/observability-and-logging/logging
                //forma mas generalista...
                /* global.prisma = new PrismaClient({
                                                    log: ['query', 'info', 'warn', 'error'],
                                                    })
                */

                //forma mas especifica, que permite tb ver los parametros de las querys...
                const PrismaClient_Debug: Prisma.PrismaClientOptions = { 
                    log: [
                            {emit: 'event',     level: 'query',},
                            {emit: 'stdout',    level: 'error',},
                            {emit: 'stdout',    level: 'info',},
                            {emit: 'stdout',    level: 'warn',},
                        ],
                    } 
                global.prisma = new PrismaClient(PrismaClient_Debug )
                //si está habilitado lo siguiente puedo ver los parametros de las queryRaw pero me aparece un error en pantalla de esos "rojos"
                //asi que comento esto cuando no necesite depurar
                // @ts-ignore
                global.prisma.$on('query', (e: any) => {
                    console.log('-------------------------------')
                    console.log('Query: ',  e.query)
                    console.log('Params: ', e.params)
                    console.log('Duration: ',  e.duration + 'ms')
                    }) 
                }
            }
        prisma = global.prisma; 

        }


        
    //esta línea de código ayuda a mejorar el rendimiento en el desarrollo al evitar la reinicialización constante del cliente de Prisma,
    //pero no es recomendable para producción, ya que puede causar problemas de memoria 
    //y en producción la aplicación se ejecute en un estado más controlado y estable, con reinicios mucho menos frecuentes
    if (process.env.NODE_ENV !== 'production') {global.prisma = prisma;} // ¿para que será esto?

    return prisma;
    }
export const prisma = Create_PrismaClient();


// Implementación de la función createPrismaClient (mi versión)
function Create_PrismaClient_v0() {
    let prisma = null;
    //para permitir depuración (por ahora var ver mejor las queryRaw)
    if (process.env.NODE_ENV === 'production') {
        prisma = global.prisma ?? new PrismaClient()
        }
    else {
        prisma = global.prisma ?? new PrismaClient(
                                                    {log: ['query', 'info', 'warn', 'error'] } //permite ver las querys reales que se ejecutan
                                                  );
        }
    //esta línea de código ayuda a mejorar el rendimiento en el desarrollo al evitar la reinicialización constante del cliente de Prisma,
    //pero no es recomendable para producción, ya que puede causar problemas de memoria 
    //y en producción la aplicación se ejecute en un estado más controlado y estable, con reinicios mucho menos frecuentes
    if (process.env.NODE_ENV !== 'production') {global.prisma = prisma;} // ¿para que será esto?

    return prisma;
    }

//sobre como usar las apis en next14 lo unico que he encontrado por ahora es
//https://www.youtube.com/watch?v=gEB3ckYeZF4
//https://nextjs.org/docs/app/building-your-application/routing/route-handlers

//- La única forma que he encontrado de crear la siguiente función es poniendo req y res como parametros de la siguiente forma
//- El parametro success es una función que se ejecuta si no hay error, y recibe como parametro el responseado de la query.
//  Se ejecuta en el servidor y es opcional
import { NextRequest, NextResponse }                    from "next/server";
import { isDevelopmentDebug } from './general';




//export async function TryCatch_SERVER(pRequest: NextRequest, pFunction: Function, pSuccessFunction?: Function) {
//parece ser que no se puede leer varias veces el body de una request por lo que lo que hago es pasarle el body ya leido
export async function TryCatch_SERVER(pRequestBody: any, pFunction: Function, pSuccessFunction?: Function) {
    //console_log("-------------------------------------------", "TryCatch_SERVER")
    //console_log("dentro de TryCatch_SERVER", "TryCatch_SERVER")
    try {
        //const request  = await pRequest.json(); //asi obtengo el body (los parametros que me han enviado)
        const request  = pRequestBody
        const response = await pFunction() // Ejecuta la operación pasada como argumento
        //console_log(["request", request], "TryCatch_SERVER")
        //console_log(["response", response], "TryCatch_SERVER")
        //console_log(["request", request], "DeleteUpdate Accommodation")
        //console_log(["response", response], "DeleteUpdate Accommodation")
        //let extended_response = JSON.parse(JSON.stringify(response));
        let extended_response = { request: request
                                , response: response}
        //console_log(["0 extended_response", extended_response], "TryCatch_SERVER")
        //let extended_response_2 = JSON.parse(JSON.stringify(extended_response));
        if (pSuccessFunction) {
            extended_response = await pSuccessFunction(extended_response);
            }
        //console_log(["1 extended_response", extended_response], "TryCatch_SERVER")
        return NextResponse.json({ status: 200
                                 , extended_response: extended_response
                                 })
        } 
    catch (error) {
        // Manejo del error
        //console_log("Error executing query:", "TryCatch_SERVER")
        let error_description: any = "Se ha producido un error en el servidor (1)."
        let error_message = "";
        let error_stack = "";
        //if (isDevelopmentDebug()) {
            //me propone chatgpt lo siguiente para tener en cuenta la compatibilidad de tipos entre el error y el error_description
            //error_description = (typeof error === 'string') ? error : error instanceof Error ? error.message : JSON.stringify(error);
            //lo anterior mas legible
            if      (typeof error === 'string') {error_message = error;} 
            //else if (error instanceof Error)    {error_description = error.message + "\nStack Trace: " + error.stack;} 
            else if (error instanceof Error)    {error_message = error.message;
                                                 error_stack   = error.stack ?? '';
                                                } 
            else                                {error_message = JSON.stringify(error);}
        //    }
        /* return NextResponse.json({status: 500,
                                  error_message,
                                  error_stack
                                }); */
        /* return NextResponse.json({
                                    error_message,
                                    error_stack,
                                    }, { status: 500 }); */
        return NextResponse.json(error_message, { status: 500 });
        }
    }

//export async function TryCatch_CLIENT_with_api(pAPI: string, pData: iFormController_body, pOnStatus200?: (message: string) => void) {
//export async function TryCatch_CLIENT_with_api(pAPI: string, pRequest: any, pOnStatus200?: (message: string) => void) {
//pSuccessFunction_or_Toast: cambio el comportamiento del tercer parametro de forma que puede adaptar 2 tipos:
// - 1 función que será lo que se ejcute con el msg generado como primer parametro
// - booleano que indica si se hace un toast o no del texto obtenido
export async function TryCatch_CLIENT_with_api(pAPI: string, pRequest: any, pSuccessFunction_or_Toast?: ((p_message: string, p_response_data: any) => boolean) | boolean) {
    try {
        const response = await fetch(pAPI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pRequest),
            });

        const response_data = await response.json();
        //ojo, lo siguiente no me dice correctamente si se ha producido un error
        //  if (response.status === 200 ) {
        //hy que hacer
        if (response_data.status === 200) {
            let msg = ""; let isError = false;
            //if (response_data.extended_response && response_data.extended_response.response && response_data.extended_response.response.controlled_error) {
            //es lo mismo que...
            if (response_data?.extended_response?.response?.controlled_error) {
                msg = response_data.extended_response.response.controlled_error;
                isError = true;
                }
            else {
                msg = msg_onSuccess(pRequest)
                }
            
            let show_toast = true;
            if (typeof pSuccessFunction_or_Toast === 'function') {
                // Si es una función, la ejecuta con el mensaje
                show_toast = pSuccessFunction_or_Toast(msg, response_data);
                } 
            
            if (typeof pSuccessFunction_or_Toast === 'boolean') {
                show_toast = pSuccessFunction_or_Toast;
                }

            if (show_toast) {
                if (isError)    {toast.error(msg);}
                else if (msg)   {toast.success(msg);}
                }
            }
        else {
            //throwCustomError(response_data);//para que devuelva el mensaje de error que haya generado el servidor
            //NO es un custom error, por lo que no se debe hacer lo anterior, ya que no quiero que se muestre el error por pantalla.
            throw new Error(response_data);//para que devuelva el mensaje de error que haya generado el servidor
            }


        //console_log(["TryCatch_CLIENT_with_api > Respuesta de la API:", response_data], "form_controller API");
        //console_log(["TryCatch_CLIENT_with_api > Respuesta de la API:", response_data], "TryCatch_SERVER");
        return response_data;
        } 
    catch (error) {
        let error_message = ""
        if (typeof error === 'string')      {error_message = error;}
        else if (error instanceof Error)    {
            if ((error as any).isCustomError) {
                error_message = error.message 
                }
            else {
                error_message = "Se ha producido un error en el servidor (2)."
                }
            }
        else                                {error_message = JSON.stringify(error)};
        toast.error(error_message);
        
        if (isDevelopmentDebug()) { console.error(error_message + ": ", error); }
        return { error_message };
        }
    }

// Función para componer un string desde un objeto obtenido con Prisma.sql mostrando los valores de los parametros
// con propositos de depuracion
export function Prisma_sql_to_string(pPrismaSql: { text: string, values: (string | number)[] }): string {
    const { text, values } = pPrismaSql;
    //busca en el texto (text) las coincidencias de un patrón regular (/\$(\d+)/g), esto encuentra todos los placeholders como $1, $2, etc.
    const str_sql = text.replace(/\$(\d+)/g, (match, index) => {
        const paramIndex = parseInt(index, 10) - 1;
        const value = values[paramIndex];
        if (typeof value === 'string') {             //Si el valor es un string, Lo envuelve en comillas simples (').
            return `'${value.replace(/'/g, "''")}'`; //Escapa las comillas simples internas (' → '') para evitar errores SQL.
            }
        return (value) ? value.toString() : "''"; //Si el valor es nulo o indefinido, devuelve una cadena vacía
                                                    //mejor doble comilla por si es un campo de tipo cadena
                                                    //y sino lo fuera por lo menos veo algo que me haga darme cuenta
        });
    
    return str_sql;
    }


//los modelos (tablas) de prisma tienen la primera letra en minuscula
export function format_Prisma_Table_Name(pTableName: keyof typeof prisma): keyof typeof prisma {
    if (typeof pTableName === 'string' ) {
        //permito que pTableName sea vacio cuando solo quiero ejecutar un pre-post function
        if (!pTableName) {
            return pTableName as keyof typeof prisma;
            }
        else {
            // Asegúrate de que el resultado sea una clave válida de tu cliente Prisma
            const TableName_formated = pTableName.charAt(0).toLowerCase() + pTableName.slice(1);
            return TableName_formated as keyof typeof prisma
            //if (TableName_formated in prisma) {return TableName_formated as keyof typeof prisma;}
            }
        }
    throwCustomError("Nombre de tabla inválido o no encontrado en Prisma");
    }

function msg_onSuccess(pData: iFormController_body): string {
    let msg_action = "";
    let msg_genero = "o";
    switch (pData.DB_action) {
        case "delete":
            msg_action = "Borrado";
            break;
        case "update":
            msg_action = "Modificación";
            msg_genero = "a";
            break;
        case "insert": case "create": 
            msg_action = "Alta";
            msg_genero = "a";
            break;
        default:
            msg_action = "Proceso";
            break;
        }

    let msg_table = "";
    switch (pData.DB_table) {
        case "TB_ALOJAMIENTOS":
            msg_table = "Alojamiento";
            break;
        case "TB_TIPOS_DE_HABITACION":
            msg_table = "Tipo de Habitación";
            break;
        case "TB_HABITACIONES":
            msg_table = "Habitación";
            break;
        case "TB_RESERVAS":
            msg_table = "Reserva";
            break;
        case "TB_JUGADORES":
            msg_table = "Jugador";
            break;
        case "TB_DELEGADOS":
            msg_table = "Delegado";
            break;
        case "TB_ACOMPANIANTES":
            msg_table = "Acompañante";
            break;
        }

    let msg_success = "";
    if (msg_action)     {msg_success = msg_action ;}
    if (msg_table)      {msg_success += " de " + msg_table;}
    if (msg_success)    {msg_success += " realizad" + msg_genero + " !";}

    //msg_success += " (" + pData.DB_action + " " + pData.DB_table + ")"; //para depuracion
    //si la DB_action no se indica o es select no muestro mensaje
    if (!pData.DB_action || pData.DB_action === "select") {msg_success = "";}

    return msg_success;
    }
    
// Elimina los registros duplicados de un array de registros
// pCOD es el campo por el cual se considera que un registro es duplicado
// En la consulta sobre la base de datos no es sencillo porque varian algunos campos, que provocan las repeticiones de registros
//aun reduciendolas a su máxima expresión el campo EXISTE, que es necesario, tb duplica los jugadores.
export function Delete_Duplicate_Records_by_COD(pRecords: any[], pCOD: string): any[] {
    const unique_CODs  = new Set();
    const unique_records = pRecords.filter(record => {
        if (unique_CODs.has(record[pCOD])) {return false; }   // Si ya existe en el conjunto, no lo incluimos
        unique_CODs.add(record[pCOD]);                        // Agregamos el valor al conjunto
        return true;                                            // Lo mantenemos si es la primera vez que aparece
        });
    return unique_records;
    }

